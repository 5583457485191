export default [
  {
    image: "network.svg",
    title: "Network",
    description:
      "Let your servers communicate through a private network and setup complex network topologies. Ideal for running a kubernetes cluster or a database server that should not be reachable publicly.",
  },
  {
    image: "volumes.svg",
    title: "Volume",
    description:
      "Volumes offer highly available and reliable SSD storage for your cloud servers. You can expand each Volume to up to 10 TB at any time and you can connect them to your Hetzner cloud servers.",
  },

  {
    image: "per.svg",
    title: "Performance",
    description:
      "AMD EPYC 2nd Gen and Intel® Xeon® Gold processors together with speedy NVMe SSDs mean you’ll profit from high performance hardware. redundant 10 Gbit network connection.",
  },
  {
    image: "backups.svg",
    title: "Backups",
    description:
      "Backups are copies of your server that we make automatically to keep your data safe. You can store up to 7 of them.",
  },
  {
    image: "ips.svg",
    title: "Floating IPS",
    description:
      "Assign floating IPs to fit your needs. Use them on a redundant server or for a highly available server cluster.",
  },
  {
    image: "images.svg",
    title: "Images",
    description:
      "Ubuntu, Debian, Fedora, and more – you have a wide variety of operating systems to choose from, and of course, we provide the most-current release.",
  },
  {
    image: "traffic.svg",
    title: "Traffics",
    description:
      "With 20 TB of included traffic, you’ll have lots of bandwidth for your projects, regardless of which Hostylus Cloud package you choose. ",
  },
  {
    image: "location.svg",
    title: "Location",
    description:
      "We have our cloud servers hosted with cloud instances in  data centers in Nuremberg, Falkenstein (Germany) and Helsinki (Finland). ",
  },
  {
    image: "ddos.svg",
    title: "DDOS Protection",
    description:
      "Hostylus Online will safeguard your Hostylus cloud servers using the latest hardware appliances and sophisticated perimeter security technologies.",
  },
];
