import PropTypes from "prop-types";
import React, { useCallback } from "react";

export default function vpsPricing(props) {
  const handleOrderNowClick = useCallback((e) => {
    e.preventDefault();

    if (typeof window !== 'undefined') {
      window.gtag('config', 'AW-16624821405');
      var callback = function () {
        if (typeof(e.target.href) != 'undefined') {
          window.open(e.target.href, '_blank');
        }
      };
      window.gtag('event', 'conversion', {
          'send_to': 'AW-16624821405/lVGXCM-Vw8MZEJ3Jqvc9',
          'value': props.priceVPS,
          'currency': 'USD',
          'event_callback': callback
      });
    } else {
      window.open(e.target.href, '_blank');
    }
  }, []);

  return (
    <div
      className="mx-3 bg-white border-solid border-gray-200 py-1 rounded-none my-2 "
      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)" }}
    >
      <h4 className="text-black text-3xl mobile:text-2xl text-center font-extrabold my-6">
        {props.headerVPS}
      </h4>
      <div
        className="w-full h-24 flex flex-wrap content-center justify-center text-center"
        style={{ backgroundColor: "#2B2B2B" }}
      >
        <div>
          <span className="m-auto text-white font-black text-4xl mobile:text-3xl block">
            {props.priceVPS} $
          </span>
          <span className="text-white font-light text-md mobile:text-lg block">
            Monthly
          </span>
        </div>
      </div>
      <div className="px-8">
        <ul
          className="font-bold list-disc"
          style={{
            listStyleImage: "url('/tick.svg')",
            listStylePosition: "inside",
            listStyleSize: "1px",
          }}
        >
          <li className="my-3 mobile:my-2">{props.vCPU} </li>
          <li className="my-3 mobile:my-2">{props.RAM}</li>
          <li className="my-3 mobile:my-2">{props.diskSpace}</li>
          <li className="mt-3 mobile:my-2">{props.traffic}</li>
        </ul>
        <a
          href={`http://client.hostylus.com/cart.php?a=add&pid=${props.pid}`}
          className="block text-center text-blue-600 font-black uppercase text-sm underline my-3 min-w-min"
          rel="noreferrer noopener"
          target="_blank"
          onClick={handleOrderNowClick}
        >
          Order Now
        </a>
      </div>
    </div>
  );
}

vpsPricing.propTypes = {
  priceVPS: PropTypes.number.isRequired,
  vCPU: PropTypes.number.isRequired,
  RAM: PropTypes.number.isRequired,
  diskSpace: PropTypes.number.isRequired,
  traffic: PropTypes.number.isRequired,
  headerVPS: PropTypes.string.isRequired,
  pid: PropTypes.string.isRequired,
};