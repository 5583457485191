import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Landing from "../components/landingSection";
import PricingVps from "../components/PricingVps";
import ImageLeft from "../components/imageLeft";
import ImageRight from "../components/imageRight";
import Feature from "../components/featureBlock";
import FeaturesVPS from "../DB/vps-features";
import Image from "../components/imageProcessing";
import AliceCarousel from "react-alice-carousel";
import { graphql, useStaticQuery, Link } from "gatsby";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  768: { items: 3 },
  1024: { items: 4 },
};

function VpsHosting() {
  const data = useStaticQuery(graphql`
  query MyQuery {
    allProducts(filter: { productID: { in: [44, 46, 48, 50, 52] } }) {
      nodes {
        productID
        productGroupID
        name
        description
        monthly
        quarterly
        semiannually
        annually
        biennially
        triennially
      }
    }
  }
`);

  const priceCard = data.allProducts.nodes.map((items, index) => {
    const stripedHtml = items.description.replace(/<[^>]+>/g, "");
    const description = stripedHtml.split("\n");

    return (
      <div key={index}>
        <PricingVps
          headerVPS={items.name}
          priceVPS={items.monthly}
          vCPU={description[0]}
          RAM={description[2]}
          diskSpace={description[4]}
          traffic={description[6]}
          pid={items.productID}
        />
      </div>
    );
  });

  return (
    <Layout>
      <SEO
        keywords={[
          "VPS Hosting", "Affordable VPS Hosting", "Cheap VPS Hosting", "Best VPS Hosting",
          "Cloud VPS Hosting", "Linux VPS Hosting", "VPS Hosting Plans", "Managed VPS Hosting",
          "High Performance VPS", "VPS Hosting Services"
        ]}
        title="VPS Hosting - Reliable and Affordable Server Solutions"
        description="Discover our VPS hosting solutions starting at $5.99. Enjoy high performance, scalable cloud infrastructure with 24/7 support."
        image="/hostylus-card-preview.jpg"
      />

      <Landing
        smallHeader=""
        header="Hostylus VPS Hosting"
        desc="Reliable and Affordable VPS Hosting Solutions!"
        boldText="Starting at $5.99"
        btnURL="#pricing"
        btnText="Get Started"
        image={<Image image="vpsLanding.png" alt="Man standing with a black server behind" />}
        hideSecondary_btn="hidden"
      />

      <section
        id="pricing"
        className="p-10 m-auto mobile:w-full laptop:w-full tablet:w-full"
        style={{ background: "linear-gradient(180deg, #2563EB 0%, #243B6D 100%)" }}
      >
        <h2 className="text-3xl text-white font-bold text-center mt-8">
          High-Quality Cloud – Unbeatable Prices
        </h2>
        <h6 className="text-white text-center mobile:p-4">
          Get the best value for your money with our scalable VPS hosting plans, optimized for various applications, distributed systems, dynamic clusters, or development environments.
        </h6>
        <div className="hidden mobile:block">
          <AliceCarousel
            mouseTracking
            infinite={true}
            disableDotsControls={false}
            disableButtonsControls={true}
            autoPlay={true}
            autoPlayInterval={6000}
            responsive={responsive}
            items={priceCard}
          />
        </div>
        <div className="grid grid-cols-5 gap-1 my-20 mobile:hidden tablet:grid-cols-3 laptop:grid-cols-4">
          {data.allProducts.nodes.map((items, index) => {
            const stripedHtml = items.description.replace(/<[^>]+>/g, "");
            const description = stripedHtml.split("\n");

            return (
              <div key={index} className="w-full">
                <PricingVps
                  headerVPS={items.name}
                  priceVPS={items.monthly}
                  vCPU={description[0]}
                  RAM={description[2]}
                  diskSpace={description[4]}
                  traffic={description[6]}
                  pid={items.productID}
                />
              </div>
            );
          })}
        </div>
      </section>

      <ImageRight
        header="Incredible Performance Starting at $5.99"
        desc="Handle even your most resource-intensive projects with ease. Our cloud servers are located in state-of-the-art data centers. You get best-in-class performance with AMD EPYC 2nd Gen, Intel® Xeon® Gold processors, and speedy NVMe SSDs."
        btnText="Deploy Your VPS Now"
        btnLink="#pricing"
        image={<Image image="per.png" alt="Group of people using their laptops, happy with Hostylus services" />}
        hideShopNow="hidden"
        hideLearnMore="hidden"
      />

      <ImageLeft
        header="Good to Go in Seconds"
        desc="Get started without waiting! With our user-friendly interface, you can create server instances almost instantly, usually in under 10 seconds."
        btnText="Deploy Your VPS Now"
        btnLink="#pricing"
        image={<Image image="gtg.png" alt="Group of people using their laptops, happy with Hostylus services" />}
        hideShopNow="hidden"
        hideLearnMore="hidden"
      />

      <ImageRight
        header="Award-Winning Services"
        desc="Hostylus VPS hosting products are provided by an award-winning provider, recognized with platinum at the Service Provider Awards 2020."
        btnText="Deploy Your VPS Now"
        btnLink="#pricing"
        image={<Image image="exe.png" alt="Group of people using their laptops, happy with Hostylus services" />}
        hideShopNow="hidden"
        hideLearnMore="hidden"
      />

      <div className="mobile:w-full text-center">
        <Link
          to="#pricing"
          className="outline-none bg-blue-600 py-2 tablet:text-xs font-bold text-white mobile:mr-3 rounded-3xl px-8 border-2 mb-2 uppercase laptop:mt-2"
        >
          Deploy Your VPS Now
        </Link>
      </div>

      <section className="features max-w-6xl mx-auto my-16 mobile:px-3 tablet:px-4 laptop:px-6">
        <h2 className="text-4xl text-black font-bold text-center">Features</h2>
        <div className="grid grid-cols-3 gap-3 flex justify-items-center mt-6 mobile:grid-cols-1 py-8 tablet:grid-cols-2 tablet:mx-5">
          {FeaturesVPS.map((items, key) => (
            <Feature
              key={key}
              image={items.image}
              header={items.title}
              desc={items.description}
              alt={items.title}
            />
          ))}
        </div>
        <div className="mobile:w-full text-center">
          <Link
            to="#pricing"
            className="outline-none bg-blue-600 py-2 tablet:text-xs font-bold text-white mobile:mr-3 rounded-3xl px-8 border-2 mb-2 uppercase laptop:mt-2"
          >
            Deploy Your VPS Now
          </Link>
        </div>
      </section>
    </Layout>
  );
}

export default VpsHosting;
